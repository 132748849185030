/* Movie Slider */

.show_play_btn {
  z-index: 2;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  position: absolute;
  box-shadow: 5px 5px 10px black;
  transition: transform 250ms ease-in-out 50ms;
}

.show_play_btn:hover {
  transform: scale(1.25) !important;
}

.hide_play_btn {
  display: none;
}

/* Top Card Slider */

.top_sec {
  height: 35rem;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-blend-mode: color;
  background-color: rgba(0, 0, 0, 0.75);
}
